import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import PageTitle from '../components/PageTitle'
import ContactForm from '../components/ContactForm';
import Map from '../components/Map';

import { FaPhone, FaEnvelope, FaMapPin } from "react-icons/fa";

const Contact = () => {
    return(
        <Layout>
        <Seo title="Contact" />
            <div className="main-container">
                <PageTitle title="Contact" />
                <div className="contact-container">
                    <ContactForm formName="contact" />
                    <div className="right-panel">
                        <div>
                            <FaPhone />
                            <p>027 744 28 50</p>
                        </div>
                        <div>
                            <FaEnvelope />
                            <p><a href="mailto:vins@caveau-saillon.ch">vins@caveau-saillon.ch</a></p>
                        </div>
                        <div>
                            <FaMapPin />
                            <p>Rue du Bourg 2, 1913 Saillon</p>
                        </div>
                    </div>
                </div>
                <h2 className="section-title" style={{margin: '1.5em 0 1em 0'}}>Accès</h2>
            </div>
            <Map />
        </Layout>
    )
}

export default Contact;
