import React, { useRef, useEffect, useState } from "react"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"

const MAPBOX_TOKEN = "pk.eyJ1IjoibGN0aHVyIiwiYSI6ImNrdGRhenhxZTBreWgyb21hMXBueHRiNHQifQ.6m9rK1R-iYeroR5jXxWpZA"

const mapContainerStyle = {
  width: "100%",
  height: "30vh",
}

const Map = () => {
  const mapContainerRef = useRef(null)
  const [map, setMap] = useState(null)

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: "mapbox://styles/mapbox/streets-v11",
      // Empire State Building [lng, lat]
      center: [7.185098347509893, 46.17207437469716],
      zoom: 15,
    })
    
    
    map.addControl(new mapboxgl.NavigationControl(), "top-right")
    setMap(map)

    const popup = new mapboxgl.Popup()
        .setText('Caveau de Saillon')
        .addTo(map);
        

    const marker = new mapboxgl.Marker()
        .setLngLat([7.185098347509893, 46.17207437469716])
        .addTo(map)
        .setPopup(popup);

    return () => map.remove()
  }, [])

  return <div ref={mapContainerRef} style={mapContainerStyle} />
}

export default Map